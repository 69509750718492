import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Features extends Component {
  render() {
    return (
      <Fragment>
        <Container className='ptb-50 features'>
          <Row>
            <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1>WELCOME TO ROSTOV STATE MEDICAL UNIVERSITY, RUSSIA</h1>
              <p>Know about Russia, Rostov-on-Don City, MBBS in Russia, University, Admission Procedure, Fees Details, FAQ about RostSMU, 100 Years Old Medical University, Official Representative of University.</p>

              <p>About Russia: Russia is the largest developed country in the world having an advanced and developed education system. It is located in both Asia and Eastern Europe. Moscow, the capital of Russia, and St. Petersburg are two prominent cities. The local and official language of the country is Russian however, 30% of the population can speak and understand English. In Russia, you will find a variety of climates from very dry summer to chilling winter with heavy snowfalls due to large territory expansion. Russia is the land of exotic and beautiful cities that attract tourists from all over the world. There are many tourist attractions such as Moscow Kremlin, Hermitage Museum, Saint Basil’s Cathedral, Trans-Siberian Railways, Kizhi Island, Suzdal, Mount Elbrus, Lake Baikal, Valley Geysers, etc. Rostov-on-Don is the capital city of Southern Russia.</p>
              <h2>MBBS in Russia</h2>
              <p>The trend of studying MBBS in Russia is all-time high due to the incredible standard of teaching quality and world-class infrastructure for medical students. There are many reasons to justify the choice of a top medical university in Russia (Rostov State Medical University). There is an unprecedented mixture of quality and affordability for those who grappled with the high-cost MBBS in Indian private medical colleges and universities. Moreover, donation, stern competition, and scarcity of seats are some of the reasons to opt for a low-cost NMC recognized medical university in Russia. The mindset of medical aspirants in India is largely influenced by the fact that Russia has become the international hub for medical education. It offers world-class medical education accompanied by state-of-the-art infrastructure and in-depth research expertise in the medical field. Russia has become the paradigm for a successful medical career. It can yield a maximum return on investment. Moreover, NMC and WHO approved low-cost MBBS destinations, affordable tuition fees, and global recognition infuse great satisfaction and motivate students and parents to choose top medical universities in Russia for medical education.</p>

              <h3>Rostov State Medical University</h3>
              <p>Russia is among the popular destinations to study medicine due to many strong factors and affordability is one of them. RostSMU is a leading choice to pursue medical education for many aspirants in India due to its easy, flexible, and student-friendly admission policy. There are many medical universities in Russia that are NMC (National Medical Commission) recognized and government-affiliated as well. Consult <strong>RREC team</strong>, the most reliable consultant who offers a complete service for MBBS admission in Russia and the only official representative of RostSMU. Parents and students are invited to participate in our one-to-one consultation for studying <strong>MBBS in Russia</strong>.</p>
              <p>Rostov State Medical University, also known as RostSMU or RostGMU, is a more than 100-year-old institution in Russia. It is the largest government medical university in southern Russian. Students choosing RostSMU will relish advanced infrastructure and modern lab facilities during the entire MBBS course in Russia. Its reputation can be assessed by the fact that a large number of alumni has been working in reputed hospitals and organization worldwide.</p>
              <p>The university’s greatest strength is the bunch of academic geniuses vastly experienced in imparting quality teaching methods and practical training to each and every student. The entire medical education system of RostSMU focuses on developing professional, updated, and advanced medical skills and knowledge to enroll students. Such facilities at affordable prices attract students from India as well as from Europe, Africa, Asia, UAE, etc. at RostSMU.</p>
              <table class="table" cellspacing="8" cellpadding="8">
                <tbody>
                  <tr>
                    <th>Location</th>
                    <th>Rostov-on-Don, Russia</th>
                  </tr>
                  <tr>
                    <td>Established</td>
                    <td>1930</td>
                  </tr>
                  <tr>
                    <td>Eligibility for Admission</td>
                    <td>NEET, 12th with 60% in PCB</td>
                  </tr>
                  <tr>
                    <td>Deadline for Admission</td>
                    <td>20th October</td>
                  </tr>
                  <tr>
                    <td>Medium of Instruction</td>
                    <td>English</td>
                  </tr>
                  <tr>
                    <td>Recognition</td>
                    <td>NMC, WHO, ECFMG</td>
                  </tr>
                  <tr>
                    <td>Duration for MBBS</td>
                    <td>6 Years</td>
                  </tr>
                </tbody>
              </table>

              <h3>Admission Procedure of RostSMU, Russia</h3>
              <Row>
                <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <ul>
                    <li>The admission procedure starts in June and ends in October. Hence being the early bird and applying for online application before the deadline will guarantee admission to their choice of a medical university in Russia.</li>
                    <li>Students should submit all the relevant documents to the team of RREC once they meet the admission eligibility 2023-24 for MBBS abroad for guaranteed admission.</li>
                    <li>The admission process is online which is smooth and hassle-free when you apply through RREC. It will diminish all the possibilities of errors in the application what may result in rejection of form and eventually academic session.</li>
                    <li>After filling and applying the online application form, the university reviews the form and if selected sends you the invitation letter which means you are selected.</li>
                  </ul>
                </Col>
                <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <ul>
                    <li>The RREC team will discharge all the admission process formalities such as tuition fees (fees should only be deposited to the university's account directly), document submission, visa, flight ticket, accommodation, and settlement in Russia.</li>
                    <li>Student can visit the Delhi office during the Visa process. Once you get the student visa for Russia. You are ready to fly to your dream destination.</li>
                    <li>For an easy and hassle-free admission procedure always rely on RREC as we are the official and authorized admission partner of top and NMC recognized Rostov state medical university.</li>
                  </ul>
                </Col>
              </Row>

              <h3>Why Choose RREC for MBBS in Rostov State Medical University, Russia?</h3>
              <p>RREC (Reliable Russian Educational Consultants) is the most trusted and authorized consultant for MBBS in Russia. We are the official representative and authorized admission partner of Rostov State Medical University. We have helped thousands of medical aspirants in India to achieve their dream goal – MBBS from a top-grade medical university in Russia. Students can check our contact number on the official website of the university and authorization certificate for their satisfaction. <strong>We are leading this profession for more than 15 years with a 100% track record. </strong>Our assistant begins with thorough counseling, confirms admission to Rostov state medical university, and extends till you complete your degree from Russia. Students can contact our representative in Russian Office anytime in case of any issue during the MBBS course. We have branches all over the country. Students and parents can approach us through email, WhatsApp and visit personally for one-to-one counseling. <strong>Complete transparency, minimum charges or fees, and no hidden cost.</strong> Parents and students can get a free consultation and expert advice to clear all their apprehension and doubts regarding <strong>MBBS in Russia</strong>.</p>
              <h4>Our contact details</h4>
              <h5>Toll-free: 1800-572-5827 (For Indian Students)</h5>
              <h5>Mob: +91 7042284508, 7042284509</h5>
              <h5>Email: <a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a>, <a href="mailto:rrec.educonsultants@gmail.com">rrec.educonsultants@gmail.com</a></h5>
              <h5>Website: www.rrecrostov.com</h5>
            </Col>
          </Row>
        </Container>

      </Fragment>
    )
  }
}

export default Features
