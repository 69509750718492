import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import BgSlider from '../../assets/images/BgSlider.webp';



class AdmissionBottom extends Component {
  render() {


    return (
      <Fragment>
        <div id="rs-calltoaction" className="rs-calltoaction sec-spacer bg4">
          <Container>
            <div className="rs-cta-inner text-center">
              <div className="sec-title mb-50 text-center">
                <h2 className="white-color">Education – Your Door To The Future.</h2>
                <p className="white-color">Rank One Medical University of Southern Russia - Rostov State Medical University ("Its a Time to be a Professional")
                </p>
              </div>
              <a className="cta-button" href="https://rrecrostov.com/apply-now/" target="_blank">Register Now For 2023</a>
            </div>
          </Container>
        </div>



      </Fragment>
    )
  }
}

export default AdmissionBottom
