import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';
import MainLogo from './MainLogo';
import Menu from './Menu';
import News from  './News';
import TopHeader from './TopHeader';




 class Header extends Component {

     render() {
          return (
               <Fragment>
            <TopHeader />
            <MainLogo/>
            <News/>
               </Fragment>
          )
     } 
}
export default Header