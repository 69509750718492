import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';

import BgSlider1 from '../../assets/images/General-Medicine-Faculty.png';
import BgSlider2 from '../../assets/images/Dentistry-Faculty.png';
import BgSlider3 from '../../assets/images/Pharmacy-Faculty.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class InnerBottom extends Component {
     render() {
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed:3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
     <Fragment>
 <div id="rs-events" className="rs-events sec-spacer">

         <Container>
         <div class="sec-title mb-50 text-center">
                    <h2>Main Faculties For International Students</h2>      
                	<p>General Medicine, Dentistry, Pharmacy, Pediatrics, Preparatory Faculty</p>
                </div>
<Row>
<Slider {...settings}>
<Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
<div className="event-item">
<div className="event-img"><img src={BgSlider1} alt=""  className='img-fluid'/><a className="image-link" href="https://rostgmu.net/" target="_blank"><i className="fa fa-link"></i></a></div>
<div className="events-details sec-color">
<h4 className="event-title"><a href="https://rostgmu.net/" target="_blank">General Medicine</a></h4>
<div className="event-meta">
 <p>The faculty offers a Six-year program of General Medicine.</p>
</div>
<div className="event-btn"><a href="https://rostgmu.net/" target="_blank">Read More <i className="fa fa-angle-double-right"></i></a></div>
</div>
</div>
  </Col>

  <Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
<div className="event-item">
<div className="event-img"><img src={BgSlider2} alt=""  className='img-fluid'/><a className="image-link" href="https://rostgmu.net/" target="_blank"><i className="fa fa-link"></i></a></div>
<div className="events-details sec-color">
<h4 className="event-title"><a href="https://rostgmu.net/" target="_blank">Dentistry</a></h4>
<div className="event-meta">
 <p>The faculty offers a five-year program of Dentistry.</p>
</div>
<div className="event-btn"><a href="https://rostgmu.net/" target="_blank">Read More <i className="fa fa-angle-double-right"></i></a></div>
</div>
</div>
  </Col>

  
  <Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
<div className="event-item">
<div className="event-img"><img src={BgSlider3} alt=""  className='img-fluid'/><a className="image-link" href="https://rostgmu.net/" target="_blank"><i className="fa fa-link"></i></a></div>
<div className="events-details sec-color">
<h4 className="event-title"><a href="https://rostgmu.net/" target="_blank">Pharmacy</a></h4>
<div className="event-meta">
 <p>The faculty offers a five-year program of Pharmacy.</p>
</div>
<div className="event-btn"><a href="https://rostgmu.net/" target="_blank">Read More <i className="fa fa-angle-double-right"></i></a></div>
</div>
</div>
  </Col>
  </Slider>
</Row>
</Container>
 
       </div>
      </Fragment>
    )
  }
}

export default InnerBottom
