import React, { Component, Fragment } from 'react'
import {Container, Row, Col,Button} from 'react-bootstrap';





 class TopHeader extends Component {

     render() {
          return (
               <Fragment>
             <div className='rs-toolbar'>
                 <Container>
                     <Row>
                     <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                     <div className="rs-toolbar-left">
					 <div className="welcome-message">
					 <i className="fa fa-building"></i><span>Welcome to study Medicine in RostSMU</span> 
					</div>
					</div>
                     </Col>

                     <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>

                     <div className="rs-toolbar-right">
								<div className="toolbar-share-icon">
									<ul>
                                    <li><a href="https://www.facebook.com/studymedicineinrostovrussia/" target="_blank"><i className="fab fa-facebook"></i></a></li>
										<li><a href="#"><i className="fab fa-twitter"></i></a></li>
										<li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
										<li><a href="#" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                        <li><div id="google_translate_element">  </div></li>
								
									</ul>
								</div>
								<a href="https://rrecrostov.com/apply-now/" target="_blank" className="apply-btn">Apply For Online Admissions</a>
                      </div>
                     </Col>
                     </Row>
                 </Container>

             </div>
               </Fragment>
          )
     } 
}
export default TopHeader