import React, { Component, Fragment } from 'react'
import Services from '../components/home/Services';
import HomeSlider from '../components/home/HomeSlider';
import Features from '../components/home/Features';
import Faq from '../components/layout/Faq';
import { Helmet } from "react-helmet";
import AdmissionBottom from '../components/home/AdmissionBottom';
import InnerBottom from '../components/home/InnerBottom';
import Youtube from '../components/home/Youtube';





export class Home extends Component {
  render() {
    
    return (
    <Fragment>
      <Helmet>
      <title>Rostov State Medical University - 7th Oldest Medical University Of Russia</title>
	<meta name="description" content="7th Oldest Medical University Of Russia" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="website" />
	<meta property="og:title" content="Rostov State Medical University" />
	<meta property="og:description" content="7th Oldest Medical University Of Russia" />
	<meta property="og:url" content="https://mbbsinrussia.co/" />
	<meta property="og:site_name" content="Rostov State Medical University" />
	<meta name="twitter:card" content="summary_large_image" />
      </Helmet>   
      <HomeSlider />
      <Services />
      <Features />
      <AdmissionBottom />
      <InnerBottom />
     <Youtube />
        </Fragment>
    )
  }
}

export default Home;
