import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card} from 'react-bootstrap';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

// import required modules
import { Navigation } from "swiper";

import BgSlider1 from '../../assets/images/MBBS-in-Russia-Rostov-2.png';
import BgSlider2 from '../../assets/images/MBBS-in-Russia-Rostov-1.png';
import BgSlider3 from '../../assets/images/MBBS-in-Russia-Rostov-3.png';



class HomeSlider extends Component {
  
     render() {
      SwiperCore.use([Autoplay]);

    return (
     <Fragment>
		 <div className="rs-slider slider-overlay-2">
  <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper"  autoplay={{ delay: 4000 }}>
      
      <SwiperSlide>
       <img className="slide-bg img-fluid" src={BgSlider1} />
            <div className="slide-content">
						<div className="display-table">
							<div className="display-table-cell">
								<div className="container text-center">
									<h1 className="slider-title" data-animation-in="fadeInLeft" data-animation-out="animate-out">World Renowned Medical University</h1>
									<p data-animation-in="fadeInUp" data-animation-out="animate-out" className="slider-desc">Rostov state medical university is 100 years old university in southern Russia. The first choice of Indian &amp; foreign students is RostSMU.<br/> Limited Seats, Quality Education, Best Climate throughout Year, Hostels are on campus. Indian Food.</p>  
									<a href="https://rrecrostov.com/" target="_blank" className="sl-readmore-btn mr-30" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Read More</a>
									<a href="https://rrecrostov.com/apply-now/" target="_blank" className="sl-get-started-btn" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Apply now</a>
								</div>
							</div>
						</div>
					</div>
           </SwiperSlide>
            

           <SwiperSlide>
       <img className="slide-bg img-fluid" src={BgSlider2} />
            <div className="slide-content">
						<div className="display-table">
							<div className="display-table-cell">
								<div className="container text-center">
									<h1 className="slider-title" data-animation-in="fadeInLeft" data-animation-out="animate-out">Education shapes people’s life.</h1>
									<p data-animation-in="fadeInUp" data-animation-out="animate-out" className="slider-desc">Choose Your University-Rostov State Medical University, Russia. It is situated in Rostov-on-Don, the capital city of Southern Russia.<br/> Rostov State Medical University was established in 1930.</p>  
									<a href="https://rrecrostov.com/faculties-fees/" target="_blank" className="sl-readmore-btn mr-30" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Faculties & Fees</a>
									<a href="https://rrecrostov.com/apply-now/" target="_blank" className="sl-get-started-btn" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Apply now</a>
								</div>
							</div>
						</div>
					</div>
           </SwiperSlide>


           <SwiperSlide>
       <img className="slide-bg img-fluid" src={BgSlider3} />
            <div className="slide-content">
						<div className="display-table">
							<div className="display-table-cell">
								<div className="container text-center">
									<h1 className="slider-title" data-animation-in="fadeInLeft" data-animation-out="animate-out">Be educated, be empowered!</h1>
									<p data-animation-in="fadeInUp" data-animation-out="animate-out" className="slider-desc">Get Direct MBBS Admission through officials only. RREC is the official admission partner of Rostov state medical university. <br/>Call +91-7042284508 and reserve your seat now.</p>  
									<a href="https://rrecrostov.com/official-representative-of-rostsmu/" target="_blank" className="sl-readmore-btn mr-30" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Contact us</a>
									<a href="https://rrecrostov.com/apply-now/" target="_blank" className="sl-get-started-btn" data-animation-in="lightSpeedIn" data-animation-out="animate-out">Apply now</a>
								</div>
							</div>
						</div>
					</div>
           </SwiperSlide>
         
           
      </Swiper>
    </>
	
	</div>
     </Fragment>
    )
  }
}

export default HomeSlider
