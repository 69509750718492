import React, { Component, Fragment } from 'react'
import {Navbar,Container, Row, Col,Button, Nav, NavDropdown} from 'react-bootstrap';
import MainLogo from './MainLogo';

class Menu extends Component {
  render() {
    return (
      <Fragment>
        
<Navbar bg="light" expand="lg" className='main-navbar'>
  <Container>
    <Navbar.Brand href="#home">  <MainLogo /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile">
      <Nav className="ms-auto menu">
        <Nav.Link href={process.env.PUBLIC_URL + '/'}>Home</Nav.Link>       
        <NavDropdown title="Medical Universities" id="basic-nav-dropdown" className='main-navbar-dropdown'>
        <NavDropdown.Item href={process.env.PUBLIC_URL + '/rostov-state-medical-university'}>Rostov state medical university</NavDropdown.Item>
         <NavDropdown.Item href={process.env.PUBLIC_URL + '/kazan-state-medical-university'}>Kazan state medical university</NavDropdown.Item>
         <NavDropdown.Item href={process.env.PUBLIC_URL + '/north-western-state-medical-university'}>North western state medical university</NavDropdown.Item>
         <NavDropdown.Item href={process.env.PUBLIC_URL + '/kazan-federal-university'}>Kazan federal university</NavDropdown.Item>
         <NavDropdown.Item href={process.env.PUBLIC_URL + '/petrozavodsk-state-university'}>Petrozavodsk state university</NavDropdown.Item>
          <NavDropdown.Item href={process.env.PUBLIC_URL + '/yaroslavl-state-medical-university-2'} >Yaroslavl state medical university</NavDropdown.Item>
           <NavDropdown.Item href={process.env.PUBLIC_URL + '/izhevsk-state-medical-academy'} >Izhevsk state medical academy</NavDropdown.Item>
           <NavDropdown.Item href={process.env.PUBLIC_URL + '/peoples-friendship-university'} >Peoples friendship university </NavDropdown.Item>
           <NavDropdown.Item href={process.env.PUBLIC_URL + '/crimea-federal-university'} >Crimea federal university</NavDropdown.Item>
        </NavDropdown>

        <NavDropdown title="Technical Universities" id="basic-nav-dropdown">     
		    <NavDropdown.Item href="http://rrec.com.ru/don-state-technical-university-russia/" target="_blank">Don state technical university</NavDropdown.Item>
            <NavDropdown.Item href="http://rrec.com.ru/southern-federal-university-russia/" target="_blank">Southern federal university</NavDropdown.Item>
            <NavDropdown.Item href="http://rrec.com.ru/rostov-state-university-economics-russia/" target="_blank">Rostov state university of economics</NavDropdown.Item>
            <NavDropdown.Item href="http://rrec.com.ru/kazan-federal-university-kazan-russia/" target="_blank">Kazan federal university</NavDropdown.Item>
            <NavDropdown.Item href="http://rrec.com.ru/ukhta-state-technical-university-russia/" target="_blank">Ukhta state technical university</NavDropdown.Item>
            <NavDropdown.Item href="http://rrec.com.ru/udmurt-state-university-russia/" target="_blank">Udmurt state university</NavDropdown.Item>
        </NavDropdown>
      
        <Nav.Link href={process.env.PUBLIC_URL + '/admission-procedure-for-study-in-russia'}>Admission procedure</Nav.Link>   
        <Nav.Link href="http://rrec.com.ru/study-in-russia-apply-online/" target="_blank">Apply Now</Nav.Link>   
        <Nav.Link href={process.env.PUBLIC_URL + '/contact-us'}>Contact Us</Nav.Link>   

      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

               </Fragment>
    )
  }
}

export default Menu
