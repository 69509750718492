import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Youtube extends Component {
  render() {

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed:3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  };
    return (
      <Fragment>
         <div className="rs-services-1">
          <Container>              
              <Row>
              <Slider {...settings}>
               <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
               <iframe width="100%" height="315" src="https://www.youtube.com/embed/nKnV8w6Hswo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>

              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/ozYcDteOwRk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>

              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/SJAKv9HBIWU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
              <iframe  width="100%" height="315" src="https://www.youtube.com/embed/d1C_DFa5v7M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
             <iframe width="100%" height="315" src="https://www.youtube.com/embed/WXsS5xa3ELw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>       
             </Col>
             <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
             <iframe width="100%" height="315" src="https://www.youtube.com/embed/khF5Wp1qg0g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </Col>
             <Col className='p-1' key={1} xl={4} lg={4} md={4} sm={6} xs={12}>
             <iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </Col>
             
              </Slider>

              </Row>
          </Container>

          </div>
          </Fragment>
    )
  }
}

export default Youtube
